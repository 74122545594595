$car-width: 48vw;
$car-height: 48vw;
$box-width: 50vw;
$box-height: 50vw;
$inner-box: 49vw;

$geared-color: #02f402;
$geared-color2: #e98512;
.k-body {
  background-color: #0d0d3d !important;
  font-family: "darkmode-off", sans-serif;
  font-variation-settings: "wght" 300;
  font-weight: 300;
}
.k-card-header, .logo {
  background-color: $geared-color !important;
}

.logo {
  margin-top: 2rem !important;
  background-color: black !important;
  margin-bottom: 2rem !important;
}

.logo-plate {
  margin-top: .5rem !important;
  //background-color: black !important;
  margin-bottom: 1rem !important;
  width: 30%;
}

.plate {
  margin: 12.5%;
  background-color: gray;
  border-radius: 2rem;
}

.plate h1 {
  font-size:3rem;
  margin-top: 0;
}

.k-card {
  margin-bottom: 1rem;
  font-size: 1.3rem;
}

#root {
  text-align: center;
  background-color: #0d0d3d;
  height: 100%;
}

//.App {
//  text-align: center;
//  background-color: ghostwhite;
//  flex-direction: column;
//  height: 100vh;
//}

.App {
  width: 100%;
  height: 100%;
  margin: auto;
  background-color: black;
  //color: #262626;
  display: flex;
  flex-direction: column;
  //gap: 20px;
  border-radius: 10px;
}

.App .logo {
  width: 100%;
  padding: 0;
  margin: 0;
  height: 32vmin;
  pointer-events: none;
}

.App hr, hr {
  height: 2px;
  border: none;
  background: #707070;
}

.App h2 {
  font-size: 27px;
  font-weight: 500;
}
.App ul {
  list-style-type: none;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 3rem;
}

.App ul li {
  align-items: center;
  height: 2rem;
  padding-left: .5rem;
  margin-bottom: 1rem;
  font-size: x-large;
  cursor: pointer;
  color: gray;
  white-space: nowrap;
}

.App button-pressed {
  border-color: green;
}

.App button {
  height: unset;
  //width: 250px;
  //height: 65px;
  //background: $geared-color;
  //color: #fff;
  font-size: 2rem;
  border-radius: 8px;
  cursor: pointer;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 1.5rem;
  border:none;
  font-weight: lighter;
  box-shadow: 0 0 5rem 5px rgba(255, 255, 255, 0.5);

}

.App .start-button, .App .replay-button {
  width: 10rem;
  height: 10rem;
  border-radius: 5rem;
  align-content: center;
  padding-left: 1.25rem;
  padding-bottom: .25rem;
  font-size: larger;
  font-weight: 500;
  color: #fff;
  z-index: 1000;
  background-color: black;
}

.App .start-button:focus, .App .replay-button:focus {
  border: 1px solid green;
  box-shadow: 0 0 5rem 5px rgba(255, 255, 255, 0.5);
}

.App .start-button {
  border: 1px solid white;
}

.App .replay-button {
  background-image: url('data:image/svg+xml,<svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path  fill="gray" d="m62 176 55.5 32C103.8 231.6 96 258.9 96 288c0 88.2 71.8 160 160 160s160-71.8 160-160-71.8-160-160-160v64L96 96 256 0v64c123.7 0 224 100.3 224 224S379.7 512 256 512 32 411.7 32 288c0-40.8 10.9-79.1 30-112"></path></svg>');
  background-size: cover;
  box-shadow:none;
}

.App .next-button {
  background-color: black;
  box-shadow: none;
  font-weight: 500;
  margin-top: 2rem;
}

.App .share-button {
  box-shadow: none;
  font-weight: 500;
}

.App .next-button > .k-button-text {
  display: flex;
  align-items: center;
}

.App .index {
  margin: auto;
  font-size: 18px;
}

.App .correct {
  font-weight: 800;
  color: #02f402;
  fill: #02f402;
}

.App .wrong {
  color: #f40202;
  font-weight: 500;
  fill: #f40202;
}

.App .introText {
  font-size: x-large;
  padding: 1rem;
  margin: 1rem;
}

.container .footer {
  //margin-top:2rem;
  height: 6rem;
  line-height: 6rem;
  button {
    margin-top: 1rem;
    margin-right: 2rem;
    height: 4rem;
    line-height: 3.8rem;
  }
}

.gears {
  height: 6rem;
  float: left;
  margin-left: 2rem;
}

.footer-logo {
  margin-right: 2rem;
  width: 100%;
  float: right;
  height: 6rem;
}

.gears-result {
  height: 20rem;
  margin-bottom: 1rem;
}

.App-header {
  background-color: #151950;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.card {
  //padding: 2em;
}

.visualizer {
  color: white;
  height: 38vmin;
  //border: 2px solid $geared-color;
  margin-bottom: 1rem;
  border-radius: 8px;
  //background-color: #3b4c6e;
  align-content: center;
}

#vis {
  height: 100%;
  width: 100%;
}

#canvas1 {
  left: 5%;
  width: 90%;
  height: 36vmin;
  z-index: 0;
  /*filter: blur(1px) contrast(10);*/
}

.share-icon {
  margin-left: 1rem;
  margin-right: 1rem;
}

#hidden-results, .hidden {
  display: none;
}

.k-flex-10 { flex: 1 1 10%; }
.k-flex-20 { flex: 1 1 20%; }
.k-flex-30 { flex: 1 1 30%; }
.k-flex-40 { flex: 1 1 40%; }
.k-flex-50 { flex: 1 1 50%; }
.k-flex-60 { flex: 1 1 60%; }
.k-flex-50 { flex: 1 1 50%; }
.k-hstack,
.k-vstack { height: 100%; }

.fillText {
  height: 100%;
}

.k-card {
  border: none;
}

.k-card-body {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: larger;
}

.flex-wrapper {
  display: flex;
  min-height: 65vh;
  flex-direction: column;
  justify-content: space-between;
  height: 90%;
}

.factoid, .fun-message {
  font-size: x-large;
  margin: auto 1rem;
}

.streaks {
  font-size: x-large;
  margin: auto 1rem;
  color: #f90;
  font-weight: 900;
  font-style: normal;
}

.timer-badge {
  font-size: 5rem;
  margin-top: 35vmin;
  width: 10rem;
  margin-left: 30vmin;
  height: 10rem;
  line-height: 10rem;
  border-radius: 5rem;
  box-shadow: 0 0 5rem 5px rgba(255, 255, 255, 0.5);
}

.border-green {
  border: 2px solid #02f402;
}

.border-yellow {
  border: 2px solid #f4f40d;
}

.border-red {
  border: 2px solid #f40202;
}

.beta-message > h2 {
  font-family: "unibody-8-new", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #02f402;
  font-size: 3rem;
}